// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function PayIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3 4c-1.093 0-2 .907-2 2v12c0 1.093.907 2 2 2h18c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2H3zm0 2h12.715v1.03c-.34.042-2.238.399-2.238 2.689 0 3.34 3.459 2.352 3.459 4.582 0 1.116-.72 1.142-.868 1.142-.134 0-1.015.07-1.015-1.636h-1.89c0 2.892 2.1 3.12 2.411 3.164V18H3V6zm13.723 0H21v12h-4.424v-1.03c.34-.042 2.25-.356 2.25-2.683 0-3.206-3.46-2.593-3.46-4.56 0-1.127.621-1.163.755-1.163.245 0 .828.217.828 1.59h1.89c0-2.628-1.795-3.036-2.116-3.097V6zM5 9v2h6V9H5zm0 4v2h5v-2H5z"
        }
      ></path>
    </svg>
  );
}

export default PayIcon;
/* prettier-ignore-end */
