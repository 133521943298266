// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function CartIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11 1v5H8l4 4 4-4h-3V1h-2zm-6.586.996l-3.41.018.01 2 2.07-.01 3.295 7.904-1.197 1.916A2.077 2.077 0 006.942 17H19v-2H6.941l-.064-.117L8.053 13h7.468a2 2 0 001.749-1.027l4.113-7.407-1.748-.97L15.52 11H8.166L4.414 1.996zM7 18a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zm10 0a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2z"
        }
      ></path>
    </svg>
  );
}

export default CartIcon;
/* prettier-ignore-end */
