// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function DashboardIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4 2c-1.093 0-2 .907-2 2v7c0 1.093.907 2 2 2h5c1.093 0 2-.907 2-2V4c0-1.093-.907-2-2-2H4zm11 0c-1.093 0-2 .907-2 2v3c0 1.093.907 2 2 2h5c1.093 0 2-.907 2-2V4c0-1.093-.907-2-2-2h-5zM4 4h5v7H4V4zm11 0h5v3h-5V4zm0 7c-1.093 0-2 .907-2 2v7c0 1.093.907 2 2 2h5c1.093 0 2-.907 2-2v-7c0-1.093-.907-2-2-2h-5zm0 2h5v7h-5v-7zM4 15c-1.093 0-2 .907-2 2v3c0 1.093.907 2 2 2h5c1.093 0 2-.907 2-2v-3c0-1.093-.907-2-2-2H4zm0 2h5v3H4v-3z"
        }
      ></path>
    </svg>
  );
}

export default DashboardIcon;
/* prettier-ignore-end */
