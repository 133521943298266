// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function FormIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4 4c-1.093 0-2 .907-2 2v12c0 1.093.907 2 2 2h16c1.093 0 2-.907 2-2V6c0-1.093-.907-2-2-2H4zm0 2h16v12H4V6zm7 2v3h7V8h-7zM6 9v2h3V9H6zm0 4v2h3v-2H6zm5 0v3h7v-3h-7z"
        }
      ></path>
    </svg>
  );
}

export default FormIcon;
/* prettier-ignore-end */
