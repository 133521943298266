// This is a skeleton starter React page generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicProcess } from "../components/plasmic/gopanda/PlasmicProcess";
import { Helmet } from "react-helmet"

function Process() {
  // Use PlasmicProcess to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicProcess are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  return (
           <>
            <Helmet>
                <title>Gopanda怎麼用?</title>
            </Helmet>
            <PlasmicProcess />
           </>
         )
}

export default Process;
